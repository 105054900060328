<template>
    <div>
        <TableList :rowSelection='rowSelection' :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
            :slot_table_list="['operation','notify_status','is_read','sign_status','factory_name','can_with_plate']" ref="list" :rowKey="'id'" >
            <template slot="left_btn">
                <a-button
                	@click="pu_ware"
                	type="primary"
                    style="margin-left: 15px;">同步仓库</a-button>

                <a-button
                	@click="pu_carrier"
                	type="primary"
                    style="margin-left: 15px;">同步承运商</a-button>

                <a-button
                	@click="pu_dealer_ware"
                	type="primary"
                    style="margin-left: 15px;">同步经销商仓库</a-button>
					
					<a-button
						@click="pu_with_plate"
						type="primary"
						:disabled="!hasSelected"
					    style="margin-left: 15px;">批量设置带板</a-button>
					             
             
            </template>
			<template slot="table_before">
			    <a-alert
			        v-if="selectedRowKeys.length"
			        style="margin-bottom:10px"
			        :message="'已选择'+selectedRowKeys.length+'项'"
			        type="info"
			        showIcon
			    />
			</template>
            <template slot="factory_name" slot-scope="data">
                {{data.record.factory_name}}
                <a-popconfirm placement="top" ok-text="确认" cancel-text="取消" @confirm="confirm(data.record.id)" title="是否删除该仓库所属工厂？">
                    <a-icon type="close-circle" v-if="data.record.factory_id"/>
                </a-popconfirm>
            </template>
			
			<template slot="can_with_plate" slot-scope="data">
			  {{data.record.can_with_plate==0?'否':'是'}}
			</template>
			
			<!--  -->
            <!-- <template slot="right_btn">
                <a :href="'/manage/crm/outbound_order_export' | export_form" target="_blank">
                    <a-button class="a_btn">导出</a-button>
                </a>
            </template> -->
            <template slot="operation" slot-scope="data">
                <a @click="go_detail(data.record)">查看</a>
                <a-divider type="vertical" />
                <a @click="go_product(data.record)">产品列表</a>
                <a-divider type="vertical" />
                <a @click="set_factory(data.record)">更改工厂</a>
            </template>
        </TableList>

        <a-modal title="提示" :visible="visible_updata" :closable='false' :closeIcon='false' :maskClosable='false'>
			<template slot="footer">
				<a-button @click="handleCancel" v-if='up_cancel'>关闭</a-button>
				<span v-else></span>
			</template>
			<p v-if="up_cancel">同步成功！</p>
			<p v-else>正在从DRP系统中同步{{visible_dealer ? '经(分)销商' : ''}}仓库，大约需要一分钟，同步成功后，会在此页面告知您！</p>
		</a-modal>
		
		<a-modal title="批量设置是否带板" :visible="visible_with_plate"   @ok="handle_with_plate"
      @cancel="handleWithPlate">
				<span>是否带板：</span>
				<a-radio-group   v-model:value="can_with_plate">
						<a-radio value="1">是</a-radio>
						<a-radio value="0">否 </a-radio>
				</a-radio-group>
			</p>
		</a-modal>
        <!-- 选择工厂 -->
		<SelectFactory @submit="submitFactory" :visible.sync="visibleFactory" :select_type="'radio'"></SelectFactory>
    </div>
</template>
<script>
    // @ is an alias to /src
    import Form from "@/components/Form";
    import TableList from "@/components/TableList";
    import {
        get_warehouse_list,
        push_ware,
        push_carrier,
        pushSellerStorage,
        // outbound_order_export
        changeFactory,
        delFactory,changeWarehouseWithPlate
    } from '@/api/push'
    import SelectFactory from '@/components/select_factory.vue'
    const columns = [{
            title: "仓库名称",
            dataIndex: "storage_name",
        },
        {
            title: "仓库编码",
            dataIndex: "storage_id",
        },
        {
            title: "所属机构编码",
            dataIndex: "storage_belong",
        },
        {
            title: "仓库类型",
            dataIndex: "storage_type_view",
        },
        {
            title: "仓库地址",
            dataIndex: "storage_address",
        },
        {
            title: "工厂名称",
            dataIndex: "factory_name",
            scopedSlots: {
                customRender: "factory_name"
            }
        },
		{
		    title: "是否带板",
		    dataIndex: "can_with_plate",
		    scopedSlots: {
		        customRender: "can_with_plate"
		    }
		},
        {
            title: "状态",
            dataIndex: "storage_state_view",
        },
        {
            title: "操作",
            scopedSlots: {
                customRender: "operation"
            }
        }
    ];

    export default {
        name: "Index",
        components: {
            Form,
            TableList,
            SelectFactory
        },
        data() {
            return {
				can_with_plate:'1',
                ck_id:'',
                visibleFactory:false,
                get_table_list: get_warehouse_list,
                columns,
                visible_dealer:false,
                visible: false,
                visible_updata:false,
				visible_with_plate:false,
                up_cancel:false,
				selectedRowKeys:[],
                form_data_seo: {
                    ...this.$config.form_data_seo,
                    list: [

                        {
                            type: "text",
                            name: "keyword",
                            title: "关键词",
                            placeholder: "请输入仓库名称/仓库编号",
                            options: {}
                        },
                        // {
                        //     type: "select",
                        //     name: "status",
                        //     list: [{
                        //             key: 1,
                        //             value: '未推送'
                        //         },
                        //         {
                        //             key: 2,
                        //             value: '已推送'
                        //         },
                        //     ],
                        //     title: "仓库类型",
                        //     options: {}
                        // },
                    ],
                },
            };
        },
        created() {
			
            Promise.all([this.$method.get_department()]).then(res => {
                this.form_data_seo.list.forEach(item => {
                    if (item.name == "department_id") {
                        item.treeData = res[0];
                    }

                });
            });

        },
		mounted() {
		    // window.addEventListener('popstate', this.handleBack_Ware_house,false);
		  },
		computed: {
		    hasSelected() {
		        return this.selectedRowKeys.length > 0;
		    },
		    rowSelection() {
		        const { selectedRowKeys } = this;
		        return {
		            selectedRowKeys,
		            onChange: (selectedRowKeys, selectedRows) => {
		                this.selectedRowKeys = selectedRowKeys;
		                this.customer_ids = selectedRowKeys.join(',')
		            },
		            hideDefaultSelections: true,
		        };
		    }
		},
		activated() {
				this.$refs.list.get_list();
		},
        methods: {
			
			handleBack_Ware_house(){
					if(this.$refs.list){
						this.$refs.list.get_list();
					}
			},
			// 设置批量带板
			handle_with_plate(){
				changeWarehouseWithPlate({data:{
					can_with_plate:this.can_with_plate,
					ids:this.customer_ids
				},info:true}).then(res=>{
					this.handleWithPlate()
					this.$refs.list.get_list();
				})
			},
			handleWithPlate(){
				this.can_with_plate='1';
				 this.customer_ids=''
				 this.selectedRowKeys=[]
				 this.visible_with_plate= false
			},
			
            //查看仓库信息
            go_detail(e) {
                this.$keep_route.add(this.$options.name);
                this.$router.push({
                    path: '/ware_house/details',
                    query: {
                        id: e.id
                    }
                })
            },
            //仓库产品列表
            go_product(e) {
                this.$router.push({
                    path: '/ware_house/product',
                    query: {
                        id: e.id
                    }
                })
            },
            //导出
            download() {
                outbound_order_export({
					data: {
						...this.$refs.list.seo_data
					}
				}).then(res => {
					let a = document.createElement('a');
					a.href = res.url;
                    a.target = '_blank'
					a.click();
				})
            },
			pu_with_plate(){
					 this.visible_with_plate= true
			},
            pu_ware() {
                this.visible_dealer = false
                this.visible_updata = true
                push_ware().then(res=>{
                    this.up_cancel= true;
                }).catch(res=>{
                    //this.up_cancel= true;
                })
            },
            pu_carrier() {
                this.visible_dealer = false
                this.visible_updata = true
                push_carrier().then(res=>{
                    this.up_cancel= true;
                }).catch(res=>{
                    //this.up_cancel= true;
                })
            },
            pu_dealer_ware(){
                this.visible_dealer = true
                this.visible_updata = true
                pushSellerStorage().then(res=>{
                    this.up_cancel= true;
                }).catch(res=>{
                    //this.up_cancel= true;
                })
            },
            handleCancel(){
                this.visible_dealer = false
                this.up_cancel= false;
                this.visible_updata = false
            },
            // 更改工厂
            set_factory(e){
                this.ck_id = e.id
                this.visibleFactory = true
            },
            submitFactory(e){
				console.log(e, this.ck_id)
                changeFactory({
                    data:{
                        id: this.ck_id ,
                        factory_id:e.data.id
                    },
                    loading:true
                }).then(()=>{
                    this.ck_id = null
                    this.$refs.list.get_list();
                })
            },
            confirm(id){
                delFactory({
                    data:{
                        id
                    },
                    loading:true
                }).then(()=>{
                    this.$refs.list.get_list();
                })
            }

        }
    };
</script>

<style lang="less" scoped>
   .anticon-close-circle{
        margin-left: 5px;
        cursor: pointer;
    }
    .anticon-close-circle:hover{
        color: #397dee ;
    }
</style>
